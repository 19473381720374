@use "@angular/material" as mat;

@mixin application-detail-theme($theme) {
  app-application-detail {
    .mat-tab-label {
      font-weight: bold;
    }

    .page-title {
      @include mat.typography-level($zonar-default-typography, display-1);
    }
  }
}