// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import "@zonar-ui/material/zonar-ui-material.theme.scss";
@import "@zonar-ui/core/zonar-ui-core.theme.scss";
@import "material-icons/iconfont/material-icons.scss";
@import "@zonar-ui/notifications/zonar-ui-notifications.theme";
/* shared components theme */
@import "../app/shared/components/detail-list/detail-list.theme.scss";
@import "../app/shared/components/transfer-list/transfer-list.theme.scss";
@import "../app/shared/components/page-not-found/page-not-found.theme.scss";
@import "../app/shared/components/tree-with-checkboxes/tree-with-checkboxes.theme.scss";
/* pages theme */
@import "../app/modules/application/pages/application-detail/application-detail.theme.scss";
@import "../app/modules/application/pages/application-list/application-list.theme.scss";
@import "../app/modules/application/pages/role-detail/role-detail.theme.scss";
@import "../app/modules/product/pages/product-detail/product-detail.theme.scss";
@import "../app/modules/product/pages/product-list/product-list.theme.scss";
@import "../app/modules/zonar-ui-breadcrumb/zonar-ui-breadcrumb.theme.scss";
@import "../app/modules/group-policy/pages/group-policy-template-list/group-policy-template-list.theme.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$product-maintenance-primary: mat.define-palette(mat.$indigo-palette);
$product-maintenance-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$product-maintenance-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$product-maintenance-theme: mat.define-light-theme(
  (
    color: (
      primary: $product-maintenance-primary,
      accent: $product-maintenance-accent,
      warn: $product-maintenance-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-core-theme($theme);
  /* include shared components mixin */
  @include page-not-found-theme($theme);
  @include detail-list-theme($theme);
  @include transfer-list-theme($theme);
  /* include pages mixin */
  @include product-list-theme($theme);
  @include product-detail-theme($theme);
  @include role-detail-theme($theme);
  @include application-list-theme($theme);
  @include application-detail-theme($theme);
  @include bread-crumb-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include group-policy-template-list-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: #f3f4f5;
}

.zonar-conti-theme {
  @include apply-zonar-theme($zonar-conti-theme);
  background-color: #f0f0f0;
}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

/* TODO: remove these sidenav css fix when sidenav implemented zui material */
$sidenavOpenWidth: 17.5rem;
$sidenavClosedWidth: 3.75rem;
.app-container-desktop {
  .mat-drawer {
    width: $sidenavOpenWidth;

    .mat-drawer-inner-container {
      overflow: hidden; // This prevents the leftnav from scrolling
    }
  }
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button-base,
.mat-button-wrapper {
  padding: 0;
  margin: 0;
  line-height: unset;
}

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
  border-radius: 0.188rem;
}

  /* Track */
  ::-webkit-scrollbar-track {
    background: #F0F0F0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #B9BEC1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #B9BEC1;
  }
/* end of sidenav css fix */
