@use "@angular/material" as mat;

@mixin transfer-list-theme($theme) {
  $background: map-get($theme, background);

  app-transfer-list {
    .list-container {
      .mat-pseudo-checkbox-checked {
        background: mat.get-color-from-palette($background, flat-button);
      }
    }
  }
}
