@use 'node_modules/@angular/material' as mat;

@mixin bread-crumb-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .breadcrumb-container {
    border-bottom: 0.1em solid mat.get-color-from-palette($primary, lighter);
    li {
      @include mat.typography-level($zonar-default-typography, caption);
    }
  }

  .color-default-icon {
    color: mat.get-color-from-palette($primary, 900);
    margin-right: 0.375rem;
  }
  .color-arrow-icon {
    color: mat.get-color-from-palette($foreground, icon-breadcrumb);
  }

  .breadcrumb-router {
    color: mat.get-color-from-palette($foreground, text-breadcrumb);
    font-family: "Open Sans";
    font-size: 0.95em;
    letter-spacing: 0;
    line-height: 1em;
    text-decoration: none;

    &.active {
      color: mat.get-color-from-palette($foreground, text-breadcrumb-active);
      font-weight: 600;
      cursor: default;
    }
  }
}
